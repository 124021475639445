<template>
  <div>
    <v-card class="custom-table">
      <v-toolbar flat height="auto" class="custom-table-toolbar">
        <v-toolbar-items
          class="d-flex align-center"
          :class="{ 'flex-wrap': $vuetify.breakpoint.width < 1280 }"
          style="width: 100%"
        >
          <v-switch
            class="mr-5 mt-2 mb-3"
            v-if="enableSelectToggle"
            v-model="tableTickboxToggle"
            inset
            dense
            hide-details
            label="啟用勾選"
          ></v-switch>
          <v-switch
            class="mr-5"
            v-if="enableWeekdayToggle"
            v-model="tableWeekdayToggle"
            inset
            dense
            hide-details
            label="顯示禮拜"
          ></v-switch>
          <v-text-field
            ref="tableSearchField"
            class="mr-3 mt-2 mb-3"
            style="width: 100%; max-width: 320px"
            v-if="enableSearch"
            v-model="searchText"
            :append-icon="mdiMagnify"
            :label="searchPlaceholder"
            single-line
            outlined
            dense
            hide-details="auto"
            clearable
            :rules="searchTextRules"
            @click:clear="clearSearch()"
            @click:append="clickSearch()"
            @keydown.enter="clickSearch()"
            @input="inputSearch()"
            @keypress="searchFieldKeypressEvent($event)"
          ></v-text-field>
          <v-menu
            style="width: 100%; max-width: 320px"
            v-if="enableDateRange"
            ref="dateRangeMenu"
            v-model="dateRangeMenu"
            :close-on-content-click="false"
            :return-value.sync="searchDateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mr-3 mt-2 mb-3"
                style="width: 100%; max-width: 320px"
                :value="displayCustomDateRange(searchDateRange)"
                :label="dateRangePlaceholder"
                :append-icon="mdiCalendarSearch"
                single-line
                outlined
                dense
                hide-details
                clearable
                readonly
                @click:clear="clearDateRange()"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchDateRange"
              range
              no-title
              scrollable
              :first-day-of-week="0"
              :day-format="$Formatter.dayFormat"
              locale="zh-hk"
            >
              <v-spacer></v-spacer>
              <v-btn depressed text small color="error" @click="dateRangeMenu = false">
                <v-icon class="mr-1" small>{{ mdiClose }}</v-icon>
                取消
              </v-btn>
              <v-btn
                depressed
                text
                small
                color="primary"
                @click="
                  $refs.dateRangeMenu.save(searchDateRange)
                  selectDateRange()
                "
              >
                <v-icon class="mr-1" small>{{ mdiCheck }}</v-icon>
                確定
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-menu
            style="width: 100%; max-width: 320px"
            v-if="enableSearchDate"
            ref="searchDateMenu"
            v-model="searchDateMenu"
            :close-on-content-click="false"
            :return-value.sync="searchDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mr-3 mt-2 mb-3"
                style="width: 100%; max-width: 320px"
                :value="searchDate"
                :label="datePlaceholder"
                :append-icon="mdiCalendarSearch"
                single-line
                outlined
                dense
                hide-details
                clearable
                readonly
                @click:clear="clearDate()"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchDate"
              no-title
              scrollable
              :first-day-of-week="0"
              :day-format="$Formatter.dayFormat"
              locale="zh-hk"
            >
              <v-spacer></v-spacer>
              <v-btn depressed text small color="error" @click="searchDateMenu = false">
                <v-icon class="mr-1" small>{{ mdiClose }}</v-icon>
                取消
              </v-btn>
              <v-btn
                depressed
                text
                small
                color="primary"
                @click="
                  $refs.searchDateMenu.save(searchDate)
                  selectDate()
                "
              >
                <v-icon class="mr-1" small>{{ mdiCheck }}</v-icon>
                確定
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-select
            v-if="enableFilter"
            :label="tableFilterLabel"
            v-model="tableFilterModel"
            class="mr-3 mt-2 mb-3"
            style="width: 100%; max-width: 320px"
            :items="tableFilterData"
            :menu-props="{ offsetY: true }"
            outlined
            hide-details
            item-text="item-text"
            item-value="item-value"
            dense
            @change="changeFilter"
          ></v-select>
          <slot name="custom-toolbar-slot"></slot>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-flex align-center">
          <v-btn
            depressed
            v-if="enableClearAll"
            text
            small
            color="error"
            @click="clickClearAll()"
            :plain="!$vuetify.theme.dark"
          >
            <v-icon class="mr-1" color="error">{{ mdiClose }}</v-icon>
            清除搜尋
          </v-btn>
          <v-btn
            depressed
            v-if="enableRefresh"
            text
            small
            color="success"
            @click="clickRefresh()"
            :plain="!$vuetify.theme.dark"
          >
            <v-icon class="mr-1" color="success">{{ mdiRefresh }}</v-icon>
            重新載入
          </v-btn>
          <v-btn
            depressed
            v-if="enableExport"
            text
            small
            color="primary"
            :plain="!$vuetify.theme.dark"
            :href="exportUrlLocation"
            target="_blank"
          >
            <v-icon class="mr-1" color="primary">{{ mdiTableArrowRight }}</v-icon>
            匯出資料
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        class="custom-table"
        locale="zh-hk"
        :loading="isLoading || uploading"
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="pageLimit"
        :page="page"
        :server-items-length="itemTotal"
        :options.sync="options"
        :height="isFixedHeader ? 'calc(3.125rem * ' + (pageLimit + 1.1) + ')' : ''"
        :fixed-header="isFixedHeader"
        :show-select="enableSelectToggle && tableTickboxToggle"
        :show-expand="enableExpand"
        :show-group-by="enableGroupBy"
        :single-select="isSingleSelect"
        :single-expand="isSingleExpand"
        :hide-default-header="disableHeader"
        :hide-default-footer="disableFooter"
        :disable-filtering="disableFiltering"
        :disable-pagination="disablePagination"
        :disable-sort="disableSort"
        :multi-sort="isMultiSort"
        :must-sort="isMustSort"
        :sort-by="defaultSortKey"
        :sort-desc="defaultSortDesc"
        :header-props="{
          sortByText: '資料排序',
        }"
        :footer-props="{
          showCurrentPage: true,
          showFirstLastPage: enableFirstLastPage,
          disablePagination: disablePagination,
          itemsPerPageText: '每頁顯示項目:',
          itemsPerPageOptions: pageLimitOptions,
          itemsPerPageAllText: '全部',
          pageText:
            '顯示第' +
            (tableData.length > 1
              ? (page - 1) * pageLimit +
                1 +
                '-' +
                Math.min(page * pageLimit, itemTotal === -1 ? tableData.length : itemTotal)
              : (page - 1) * pageLimit + 1) +
            '個項目',
        }"
        loading-text="資料處理中"
        no-data-text="没有資料"
        no-results-text="没有資料"
        :dense="isDense"
        :mobile-breakpoint="breakpoint"
      >
        <template v-slot:top="{ pagination, options, updateOptions }" v-if="showTopSlot">
          <v-data-footer
            class="hide-page-select"
            :show-current-page="true"
            :disable-items-per-page="true"
            :pagination="pagination"
            :options="options"
            :show-first-last-page="enableFirstLastPage"
            :items-per-page-text="''"
            :page-text="
              '共' +
              (itemTotal % pageLimit == 0 ? itemTotal / pageLimit : parseInt(itemTotal / pageLimit) + 1) +
              '頁 / ' +
              itemTotal +
              '個項目'
            "
            @update:options="updateOptions"
          >
          </v-data-footer>
        </template>

        <!-- pass through scoped slots -->
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
          <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <!-- pass through normal slots -->
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
          <slot :name="slotName" />
        </template>

        <!-- <template v-slot:[`header.admin_type`]="{}">
          <v-select
            clearable
            dense
            outlined
            :menu-props="{ offsetY: true }"
            hide-details
            :items="filterSlotList"
            item-text="item-text"
            item-value="item-value"
            :placeholder="'管理員類別'"
            @change="filterSlotData($event)"
            @click:clear="filterSlotData('')"
          ></v-select>
        </template> -->

        <template v-slot:[`item.create_date`]="{ item }">
          {{ item.create_date.split('T')[0] + ' ' + item.create_date.split('T')[1].substring(0, 8) }}
        </template>

        <template v-slot:[`item.admin_type`]="{ item }">
          {{ item.admin_type | formatAdminType }}
        </template>

        <template v-slot:[`item.staff_type`]="{ item }">
          {{ item.staff_type | formatStaffType }}
        </template>

        <template v-slot:[`item.staff_status`]="{ item }">
          {{ item.staff_status | formatStaffStatus }}
        </template>

        <template v-slot:[`item.absent_type`]="{ item }">
          {{ item.absent_type | formatAbsentType }}
        </template>

        <template v-slot:[`item.absent_time`]="{ item }">
          {{ item.absent_time | formatAbsentTime }}
        </template>

        <template v-slot:[`item.approve_status`]="{ item }">
          {{ item.approve_status | formatApproveStatus }}
        </template>

        <template v-slot:[`item.check_in_type`]="{ item }">
          {{ item.check_in_type | formatCheckInType }}
        </template>

        <template v-slot:[`item.check_in_method`]="{ item }">
          {{ item.check_in_method | formatCheckInMethod }}
        </template>

        <template v-slot:[`item.view_proof`]="{ item }">
          <v-btn
            color="primary"
            text
            small
            @click="openProofLink(item)"
            :disabled="isLoading || uploading || disablePagination"
            >{{ item.check_in_method === 'gps' ? '查看地圖' : '查看圖片' }}</v-btn
          >
        </template>

        <template v-slot:[`item.user_status`]="{ item }">
          <span :class="{ 'error--text': item.user_status !== 'active' }">{{
            item.user_status === 'active' ? '啟用' : '停用'
          }}</span>
        </template>

        <template v-slot:[`item.salary_status`]="{ item }">
          {{ item.salary_status | formatSalaryStatus }}
        </template>

        <template v-slot:[`item.phone`]="{ item }">
          {{
            item.phone !== undefined && item.phone.length === 8
              ? item.phone.substring(0, 4) + ' ' + item.phone.substring(4)
              : item.phone
          }}
        </template>

        <template v-slot:[`item.image`]="{ item }">
          <v-img
            class="my-2"
            :src="$validate.DataValid(item.image) ? $mediaPath + item.image : require('@/assets/empty-image.svg')"
            :max-width="250"
            :max-height="150"
            cover
          ></v-img>
        </template>

        <template v-slot:[`item.log_action`]="{ item }">
          {{ item.log_action | formatLogAction }}
        </template>

        <template v-slot:[`item.actionDetails`]="{ item }">
          <v-btn depressed text color="secondary" small @click="emitDetailsClickEvent(item.id)">
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            查看
          </v-btn>
        </template>

        <template v-slot:[`item.actionView`]="{ item }">
          <v-btn depressed text color="error" small :to="{ name: actionViewRouteLink, params: { id: item.id } }">
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            查看
          </v-btn>
        </template>

        <template v-slot:[`item.viewSalaryDetail`]="{ item }">
          <v-btn depressed text color="error" small :to="{ name: actionViewRouteLink, params: { staff_id: item.staff_id } }">
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            查看
          </v-btn>
        </template>

        <template v-slot:[`item.invoice_action`]="{ item, index }">
          <v-btn
            depressed
            text
            small
            :disabled="!item.show_rental_invoice"
            color="accent"
            @click="tableDownloadInvoice(index)"
            >下載發票</v-btn
          >
          <v-btn depressed text small :disabled="item.invoice_status === 'unpaid'" @click="tableDownloadReceipt(index)"
            >下載收據</v-btn
          >
          <v-btn
            depressed
            text
            small
            :to="{
              name: item.invoice_type === 'rent' ? 'RentInvoiceDetail' : 'OtherInvoiceDetail',
              params: { id: item.id },
            }"
          >
            <v-icon class="mr-1" small>
              {{ mdiEyeOutline }}
            </v-icon>
            查看
          </v-btn>
        </template>

        <slot></slot>
      </v-data-table>
    </v-card>

    <a href="" target="_blank" download="" id="table-invoice-download" style="display: none"></a>
    <a href="" target="_blank" download="" id="table-receipt-download" style="display: none"></a>
  </div>
</template>

<script>
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
import {
  mdiEyeOutline,
  mdiTrashCanOutline,
  mdiCheckCircle,
  mdiAlert,
  mdiAlertOutline,
  mdiCancel,
  mdiCheck,
  mdiClose,
  mdiDeleteForever,
  mdiRefresh,
  mdiMagnify,
  mdiCalendarSearch,
  mdiNotePlusOutline,
  mdiTableArrowRight,
  mdiEmail,
  mdiPhone,
  mdiCalendarClock,
  mdiCardAccountDetails,
} from '@mdi/js'

export default {
  // ------ page properties ------
  name: 'Datatable',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    tableFilterData: {
      type: Array,
      default: () => [{ 'item-text': '全部', 'item-value': 'all' }],
    },
    tableFilterLabel: {
      type: String,
      default: '',
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    defaultSortKey: {
      type: Array,
      default: () => ['create_date'],
    },
    defaultSortDesc: {
      type: Array,
      default: () => [true],
    },
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    isSingleExpand: {
      type: Boolean,
      default: false,
    },
    isMustSort: {
      type: Boolean,
      default: true,
    },
    isMultiSort: {
      type: Boolean,
      default: false,
    },
    isFixedHeader: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    enableSelectToggle: {
      type: Boolean,
      default: false,
    },
    enableWeekdayToggle: {
      type: Boolean,
      default: false,
    },
    enableRefresh: {
      type: Boolean,
      default: false,
    },
    enableExport: {
      type: Boolean,
      default: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    enableFilter: {
      type: Boolean,
      default: false,
    },
    enableDateRange: {
      type: Boolean,
      default: false,
    },
    enableSearchDate: {
      type: Boolean,
      default: false,
    },
    enableClearAll: {
      type: Boolean,
      default: false,
    },
    enableExpand: {
      type: Boolean,
      default: false,
    },
    enableGroupBy: {
      type: Boolean,
      default: false,
    },
    enableFirstLastPage: {
      type: Boolean,
      default: true,
    },
    disableSort: {
      type: Boolean,
      default: true,
    },
    disableFiltering: {
      type: Boolean,
      default: true,
    },
    disableHeader: {
      type: Boolean,
      default: false,
    },
    disableFooter: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    pageLimitOptions: {
      type: Array,
      default: () => [5, 10, 20, 50],
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLimit: {
      type: Number,
      default: 10,
    },
    breakpoint: {
      type: Number,
      default: 960,
    },
    actionViewRouteLink: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: '搜尋',
    },

    searchTextRules: {
      type: Array,
      default: () => [],
    },
    searchNumberOnly: {
      type: Boolean,
      default: false,
    },
    dateRangePlaceholder: {
      type: String,
      default: '按日期範圍搜尋',
    },
    datePlaceholder: {
      type: String,
      default: '搜尋日期',
    },
    confirmDeleteDes: {
      type: String,
      default: '此動作將無法還原',
    },
    exportUrl: {
      type: String,
      default: '/api/cms-export',
    },
    filterSlot: {
      type: Boolean,
      default: false,
    },
    filterSlotList: {
      type: Array,
      default: () => [],
    },
    showTopSlot: {
      type: Boolean,
      default: true,
    },
  },

  // ------ page variables ------
  data: () => ({
    tableInit: false,
    tableWeekdayToggle: true,
    tableTickboxToggle: false,
    tableFilterModel: 'all',
    options: {},
    dialogConfirmDelete: {
      show: false,
      loading: false,
      data_type: '',
      data_id: -1,
    },
    searchText: '',
    searchDateRange: [],
    dateRangeMenu: false,
    searchDate: '',
    searchDateMenu: false,

    dataDeleted: false,
    dataFailedToDelete: false,

    // icon
    mdiEyeOutline,
    mdiTrashCanOutline,
    mdiAlertOutline,
    mdiCancel,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
    mdiRefresh,
    mdiMagnify,
    mdiCalendarSearch,
    mdiNotePlusOutline,
    mdiCheckCircle,
    mdiAlert,
    mdiTableArrowRight,
    mdiEmail,
    mdiPhone,
    mdiCalendarClock,
    mdiCardAccountDetails,
  }),

  // ------ mutation observer ------
  watch: {
    options: {
      handler() {
        this.updateOptions()
      },
      deep: true,
    },
  },

  // ------ computed values ------
  computed: {
    exportUrlLocation() {
      let url = this.exportUrl + '?sort='
      if (this.options && this.options.sortBy && this.options.sortBy.length) {
        url += this.options.sortBy[0]
      } else {
        url += 'create_date'
      }
      if (this.options && this.options.sortDesc && this.options.sortDesc.length) {
        url += '&order=' + ((this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : true) ? 'desc' : 'asc')
      } else {
        url += '&order=desc'
      }
      if (this.searchText !== '') {
        url += '&search=' + encodeURIComponent(this.searchText)
      }

      if (this.searchDateRange.length >= 2) {
        url += '&start_date=' + this.searchDateRange[0]
        url += '&end_date=' + this.searchDateRange[1]
      } else if (this.searchDateRange.length === 1) {
        url += '&start_date=' + this.searchDateRange[0]
        url += '&end_date=' + this.searchDateRange[0]
      }

      return url
    },
    uploading() {
      return this.$store.getters.isLoading
    },
  },

  // ------ life cycle events ------
  created() {},

  // ------ page functions ------
  methods: {
    displayCustomDateRange(date) {
      if (date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          return `${date[1]} (${this.$Formatter.displayWeekday(date[1])}) ~ ${
            date[0]
          } (${this.$Formatter.displayWeekday(date[0])})`
        } else {
          return `${date[0]} (${this.$Formatter.displayWeekday(date[0])}) ~ ${
            date[1]
          } (${this.$Formatter.displayWeekday(date[1])})`
        }
      } else if (date.length === 1) {
        return `${date[0]} (${this.$Formatter.displayWeekday(date[0])})`
      }

      return ''
    },
    searchFieldKeypressEvent(event) {
      if (this.searchNumberOnly) {
        return this.$format.number(event)
      }
      return event
    },
    updateOptions() {
      if (this.tableInit) {
        this.$emit('options-update', this.options)
      }
      this.tableInit = true
    },
    clickRefresh() {
      this.$emit('refresh-clicked')
    },
    clickExport() {
      this.$emit('export-clicked')
    },
    clickSearch() {
      this.$emit('search-clicked', this.searchText)
    },
    inputSearch() {
      this.$emit('search-input', this.searchText)
    },
    clearSearch() {
      this.$emit('search-cleared')
    },
    selectDateRange() {
      if (this.searchDateRange.length === 2) {
        if (new Date(this.searchDateRange[0]).getTime() > new Date(this.searchDateRange[1]).getTime()) {
          this.$emit('daterange-selected', [this.searchDateRange[1], this.searchDateRange[0]])
        } else {
          this.$emit('daterange-selected', this.searchDateRange)
        }
      } else {
        this.$emit('daterange-selected', this.searchDateRange)
      }
    },
    clearDateRange() {
      this.searchDateRange = []
      this.$emit('daterange-cleared')
    },
    selectDate() {
      this.$emit('date-selected', this.searchDate)
    },
    clearDate() {
      this.searchDate = ''
      this.$emit('date-cleared')
    },
    clickClearAll() {
      this.searchText = ''
      this.searchDate = ''
      this.searchDateRange = []
      this.tableFilterModel = 'all'
      this.$emit('all-search-cleared')
    },
    changeFilter() {
      this.$emit('filter-changed', this.tableFilterModel)
    },
    emitDetailsClickEvent(dataId) {
      this.$emit('details-clicked', dataId)
    },
    filterSlotData(value) {
      this.$emit('filter-slot-data', value)
    },
    tableDownloadInvoice(index) {
      if (!this.uploading) {
        this.$store.dispatch('setLoading', true)

        if (this.$validate.DataValid(this.tableData[index].invoice_file)) {
          let btn = document.getElementById('table-invoice-download')
          if (btn) {
            btn.setAttribute('href', `${this.$mediaPath}${this.tableData[index].invoice_file}`)
            btn.setAttribute('download', `${this.tableData[index].invoice_file}`)
          }

          btn.click()
        } else {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '沒有發票可供下載',
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }

        this.$store.dispatch('setLoading', false)
      }
    },
    tableDownloadReceipt(index) {
      if (!this.uploading) {
        this.$store.dispatch('setLoading', true)

        if (this.$validate.DataValid(this.tableData[index].receipt_file)) {
          let btn = document.getElementById('table-receipt-download')
          if (btn) {
            btn.setAttribute('href', `${this.$mediaPath}${this.tableData[index].receipt_file}`)
            btn.setAttribute('download', `${this.tableData[index].receipt_file}`)
          }

          btn.click()
        } else {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '沒有收據可供下載',
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }

        this.$store.dispatch('setLoading', false)
      }
    },
    openProofLink(item) {
      if (item.check_in_method === 'gps') {
        if (this.$validate.DataValid(item.latlng)) {
          // https://stackoverflow.com/questions/1801732/how-do-i-link-to-google-maps-with-a-particular-longitude-and-latitude
          window.open(`https://www.google.com/maps/search/?api=1&query=${item.latlng.lat},${item.latlng.lng}`)
        }
      } else {
        if (this.$validate.DataValid(item.proof_image)) {
          window.open(this.$mediaPath + item.proof_image)
        }
      }
    },
  },
}
</script>
